import ApiService from "@/common/api.service";

export const deleteTask = {
  async deleteTask({ commit }, payload) {
    try {
      await ApiService.deleteWithBody('stores/tasks-delete', {
        data: { ...payload }
      });
      commit('SET_RESPONSE_MESSAGE', { message: 'Task deleted successfully.', type: 'primary' });
    } catch(error) {
      commit('SET_RESPONSE_MESSAGE', { message: 'Something went wrong.', type: 'error' });
    }
  }
};
