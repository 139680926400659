<template>
  <v-app class="grey lighten-4">
    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
  getterType: 'shops/getField',
  mutationType: 'shops/updateField',
});

export default {
  name: 'App',

  computed: {
    ...mapFields([
      "jailedStoresList",
      "processingGroups"
    ])
  },

  async mounted() {
    const response = await axios("networks/assign-processing-groups")
    this.jailedStoresList = response.data.list
    this.processingGroups = response.data["processing-group"]
  }
};
</script>

<style>
  .v-application--wrap {
    min-height: calc(100vh - 64px) !important;
  }

  .ripple-loading {
    background: rgba(255,255,255,0.5);
    border-radius: 15px;
    display: flex;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .ripple-loading div {
    animation: ripple-loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    border-radius: 50%;
    border: 4px solid #623f06;
    left: 50% !important;
    opacity: 1;
    position: absolute;
    top: 50% !important;
    transform: translateX(-50%) translateY(-50%);
  }

  .ripple-loading div:nth-child(2) {
    animation-delay: -0.5s;
  }

  @keyframes ripple-loading {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 50px;
      height: 50px;
      opacity: 0;
    }
  }
</style>