import ApiService from "@/common/api.service";

export const createTask = {
  async createTask({ commit }, payload) {
    try {
      await ApiService.post("/stores/tasks-create", {
        ...payload
      });
      commit('SET_RESPONSE_MESSAGE', { message: 'Task created successfully.', type: 'primary' });
    } catch (error) {
      commit('SET_RESPONSE_MESSAGE', { message: 'Something went wrong.', type: 'error' });
    }
  }
};
