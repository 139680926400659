import deepmerge from "deepmerge";
import { updateField } from 'vuex-map-fields';

import { createNote } from "./actions/createNote";
import { deleteNote } from "./actions/deleteNote";
import { fetchNotes } from "./actions/fetchNotes";
import { updateNote } from "./actions/updateNote";

import { setLoading } from "./mutations/setLoading";
import { setNotes } from "./mutations/setNotes";
import { setResponseMessage } from "./mutations/setResponseMessage";

/* Actions */
const actions = deepmerge.all([
  createNote,
  deleteNote,
  fetchNotes,
  updateNote
]);

/* Mutations */
const mutations = deepmerge.all([
  setLoading,
  setNotes,
  setResponseMessage
]);

const state = {
  isLoading: true,
  notes: [],
  responseMessage: ''
};

import { getters } from "./getters";

mutations['updateField'] = updateField

export default {
  actions,
  getters,
  mutations,
  state,
  namespaced: true
};
