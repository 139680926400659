import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth.module';
import configs from '@/store/modules/configurations.module';
import notes from '@/store/modules/notes';
import plans from '@/store/modules/plans.module';
import reporting from '@/store/modules/reporting.module';
import reports from '@/store/modules/reports';
import shops from '@/store/modules/shops.module';
import staffs from '@/store/modules/staffs.module';
import tasks from '@/store/modules/tasks';
import users from '@/store/modules/users.module';
import webhooks from '@/store/modules/webhooks';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    configs,
    notes,
    plans,
    reporting,
    reports,
    shops,
    staffs,
    tasks,
    users,
    webhooks
  }
});
