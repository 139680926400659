export const SET_AUTH = "setAuth";
export const RESET_TOKEN = "resetToken";
export const SET_USERS = "setUsers";
export const SET_USERS_LIST = "setUsersList";
export const UNSET_USERS_LIST = "unsetUsersList";
export const SET_LOADING = "setLoading";
export const UNSET_LOADING = "unsetLoading";
export const SET_ERROR = "setError";
export const UNSET_ERROR = "unsetError";
export const PURGE_AUTH = "logOut";
export const SET_CURRENT_PAGE = "setCurrentPage";
export const SET_STAFFS = "setStaffs";
export const SET_STAFFS_LIST = "setStaffsList";
export const UNSET_STAFFS_LIST = "unsetStaffsList";
export const SET_CURRENT_STAFF = "setCurrentStaff";

export const SET_SHOPS = "setShops";
export const SET_SHOPS_LIST = "setShopsList";
export const UNSET_SHOPS_LIST = "unsetShopsList";
export const SET_CURRENT_SHOP = "setCurrentShop";
export const SET_CURRENT_SHOP_CONNECTIONS = "setCurrentShopConnections";
export const SET_STORES_PAGINATION = "setStoresPagination";

export const SET_CURRENT_CONFIGS = "setCurrentConfigs";
export const SET_USER_MODULES = "setUserModules";
