import deepmerge from "deepmerge";
import { updateField } from 'vuex-map-fields';
import { generateOrderReports } from "./actions/generateOrderReports";
import { setResponseMessage } from "./mutations/setResponseMessage";

/* Actions */
const actions = deepmerge.all([
  generateOrderReports,
  {}
]);

/* Mutations */
const mutations = deepmerge.all([
  setResponseMessage,
  {}
])

const state = {
  orderReportForm: {
    destinationStore: null,
    endDate: null,
    location: null,
    sourceStore: null,
    startDate: null,
    withSync: null
  },
  responseMessage: {
    message: '',
    type: ''
  }
};

import { getters } from "./getters";

mutations['updateField'] = updateField

export default {
  actions,
  getters,
  mutations,
  state,
  namespaced: true
};
