import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "LoginPage",
    component: () => import("@/views/LoginPage"),
  },
  {
    path: "/",
    name: "DashboardLayout",
    component: () => import("@/views/layouts/DashboardLayout"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/views/Dashboard"),
      },
      {
        path: "/users",
        name: "UsersListingPage",
        component: () => import("@/views/UsersListingPage"),
      },
      {
        path: "/users/:id/plan",
        name: "CustomPlanManagementPage",
        component:() => import("@/views/pages/plan/CustomPlanManagementPage"),
      },
      {
        path: "/staffs",
        name: "StaffsListingPage",
        component: () => import("@/views/StaffsListingPage"),
      },
      {
        path: "/stores/",
        name: "StoresListingPage",
        component: () => import("@/views/pages/shop/StoresListingPage"),
        children: [],
      },
      {
        path: "/stores/:id",
        name: "SingleStorePage",
        component: () => import("@/views/pages/shop/SingleStorePage.vue"),
      },
      {
        path: "/stores/:id/webhooks",
        name: "StoreWebhooksManagementPage",
        component: () => import("@/views/pages/shop/StoreWebhooksManagementPage"),
      },
      {
        path: "/stores/:id/configs",
        name: "ProductSyncConfigurationsPage",
        component: () => import("@/views/pages/shop/ProductSyncConfigurationsPage"),
      },
      {
        path: "/stores/:id/notes",
        name: "Notes",
        component: () => import("@/views/pages/shop/Notes"),
      },
      {
        path: "/stores/:id/tasks",
        name: "Tasks",
        component: () => import("@/views/pages/shop/Tasks"),
      },
      {
        path: "/reports",
        name: "Reports",
        component: () => import("@/views/pages/reports/Reports"),
      },
      {
        path: "/servers",
        name: "Servers",
        component: () => import("@/views/pages/servers/Servers"),
      }
    ],
  },
  {
    path: "*",
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
