import Vue from "vue";
import Vuelidate from "vuelidate";
import axios from "axios";
import App from "@/App";
import router from "@/router";
import store from "@/store";
import ApiService from "./common/api.service";
import vuetify from "@/plugins/vuetify";
import { CHECK_AUTH } from "@/store/actions.type";
import './filters';
import VueClipboard from 'vue-clipboard2';

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
ApiService.init();

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          alert("session expired");
          break;
        case 403:
          router.replace({
            path: "/login",
            query: { redirect: router.currentRoute.fullPath },
          });
          break;
        case 502:
          setTimeout(() => {
            router.replace({
              path: "/login",
              query: {
                redirect: router.currentRoute.fullPath,
              },
            });
          }, 1000);
      }
      return Promise.reject(error.response);
    }
  }
);

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  const { isAuthenticated } = store.state.auth;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        name: "LoginPage",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
Vue.use(Vuelidate);

// Use Promise race to make sure
// If Check auth get to long than 500 ms
// Generate Vue instance
// Just avoid blank page
Promise.race([
  store.dispatch(`auth/${CHECK_AUTH}`, null),
  new Promise((resolve) => setTimeout(resolve, 500)),
]).then(() => {
  /* eslint-disable no-new */
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
