import ApiService from "@/common/api.service";

export const fetchNotes = {
  async fetchNotes({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const response = await ApiService.get(`stores/${payload}/notes`);
      commit('SET_NOTES', response.data.notes);
      commit('SET_LOADING', false);
    } catch(error) {}
  }
};
