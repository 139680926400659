import ApiService from "@/common/api.service";
import moment from "moment";

export const generateOrderReports = {
  async generateOrderReports({ commit }, payload) {
    try {
      const response = await ApiService.post('reports/sales', {
        ...payload
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.download = `salesData_${moment().format("MM-DD-YYYY")}.csv`;
      link.click()
      commit('SET_RESPONSE_MESSAGE', { message: 'Report generated successfully.', type: 'primary' });
    } catch (error) {
      commit('SET_RESPONSE_MESSAGE', { message: 'Something went wrong. Please try again.', type: 'error' });
    }
  }
}
