import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { API_URL, VUE_APP_ID } from "@/common/config";

import JwtService from "@/common/jwt.service";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.defaults.headers.common["x-syncio-app-id"] = VUE_APP_ID;
  },
  setHeader() {
    const token = JwtService.getToken();
    if (token) {
      Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  },
  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  get(resource, slug = "") {
    const subpath = slug ? `/${slug}` : ``;
    return Vue.axios.get(`${resource}` + subpath).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  deleteWithBody(resource, params) {
    return Vue.axios.delete(`${resource}`, params);
  }
};

export default ApiService;

export const AuthApiService = {
  login(email, password) {
    return ApiService.post("login", { email, password });
  },
  getAdmin(adminId) {
    return ApiService.get("users", adminId);
  },
};

export const UserApiService = {
  getUser(userId) {
    return ApiService.get("users", userId);
  },
  registerUser(name, email, password, role) {
    return ApiService.post("/users", { name, email, password, role });
  },
  deleteUser(id) {
    return ApiService.delete(`/users/${id}`);
  },
  updateUser(slug, params) {
    return ApiService.update("users", slug, { user: params });
  },
  getUsers(pageNumber, searchStr) {
    return ApiService.get(
      "/users",
      `?page=${pageNumber}&searchStr=${searchStr}`
    );
  },
};

export const StaffApiService = {
  getStaff(staffId) {
    return ApiService.get("users", staffId);
  },
  registerStaff(params) {
    return ApiService.post("/staffs", params);
  },
  deleteStaff(id) {
    return ApiService.delete(`/staffs/${id}`);
  },
  updateStaff(slug, params) {
    return ApiService.update("staffs", slug, params);
  },
  getStaffs(pageNumber, searchStr) {
    return ApiService.get(
      "/staffs",
      `?page=${pageNumber}&searchStr=${searchStr}`
    );
  },
};

export const ShopApiService = {
  getShops(pageNumber, searchStr) {
    return ApiService.get(
      "stores",
      `?page=${pageNumber}&searchStr=${searchStr}`
    );
  },
  getShop(id) {
    return ApiService.get(`stores/${id}`);
  },
  getShopConnections(id, limiter, search_str, page) {
    return ApiService.get(`stores/${id}/connections?limiter=${limiter}&search_str=${search_str}&page=${page}`);
  },
  // updateShop(slug, params) {
  //     return ApiService.update("stores", slug, { store: params });
  // },
  getAvailableWebhooksDefinitions() {
    return ApiService.get(`webhooks`);
  },
  getShopWebhooksByShopId(id) {
    return ApiService.get(`stores/${id}/webhooks`);
  },
  getShopDebugWebhooksByShopId(id) {
    return ApiService.get(`stores/${id}/webhooks/debug`);
  },
  addingShopWebhook(shopId, webhook) {
    return ApiService.post(`stores/${shopId}/webhooks`, webhook);
  },
  removingShopWebhook(shopId, webhook) {
    return ApiService.delete(`stores/${shopId}/webhooks/${webhook.id}`);
  },
  getProductCounters(shopId) {
    return ApiService.get(`stores/${shopId}/products/count`);
  },
  fetchProductsByStoreId(shopId) {
    return ApiService.get(`stores/${shopId}/fetch-product`);
  },
  deleteStore(shopId){
    return ApiService.delete(`stores/${shopId}`);
  },
  updatePermission(storeDomain){
    return ApiService.get(`stores/process-renew-api-token/${storeDomain}`);
  },
  resyncProductsByConnectionId(connectionId) {
    return ApiService.get(`connections/${connectionId}/resync-products`);
  },
  fetchProductCleanup(shopId) {
    return ApiService.get(`stores/${shopId}/products/cleanup-fetch-duplicate`);
  },
};

export const ConfigsApiService = {
  loadConfigurations(id) {
    return ApiService.get(`stores/${id}/config`);
  },
  saveConfigurations(shopId, data) {
    return ApiService.put(`stores/${shopId}/config`, {
          configurations: data
      });
  }
}

export const ModuleApiService = {
  loadModuleByUserId(userId) {
    return ApiService.get(`users/${userId}/modules`);
  },
  saveModuleByUserId(userId, data) {
    return ApiService.put(`users/${userId}/modules`, {
        modules: [data]
    });
  }
}

export const PlanApiService = {
  getAvailablePlans() {
    return ApiService.get(`plans`);
  },
  getUserPlan(userId) {
    return ApiService.get(`users/${userId}/plan`);
  },
  createCustomPlan(userId, data) {
    return ApiService.post(`users/${userId}/plan`, data);
  }
}

export const ReportingApiService = {
  // fetchStoresCount() {
  //   return ApiService.get(`reporting/store-counts`);
  // },
  getDashBoardData() {
    return ApiService.get(`reports/dashboard-data`);
  },
}