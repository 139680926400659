import ApiService, { AuthApiService } from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import SessionData from "@/common/sessionData.service"
import {
    LOGIN,
    LOGOUT, CHECK_AUTH} from "@/store/actions.type";
import {SET_AUTH, PURGE_AUTH, SET_ERROR, UNSET_ERROR} from "@/store/mutations.type";

const initialState = {
    admin_id: !!SessionData.getAdminID(),
    errors: [],
    isAuthenticated: !!JwtService.getToken(),
    admin: {}
};

export const state = { ...initialState };

const getters = {
    isAuthenticated (state) {
        return state.isAuthenticated;
    },
};

const actions = {
    [LOGIN](context, {email, password}) {
        return new Promise(resolve => {
            AuthApiService.login(email, password)
                .then((response) => {
                    context.commit(SET_AUTH, response);
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.data);
                });
        });
    },
    [LOGOUT](context) {
        context.commit(PURGE_AUTH);
    },
    [CHECK_AUTH](context) {
        return new Promise((resolve) => {
            if (JwtService.getToken() && SessionData.getAdminID()) {
                    ApiService.setHeader()
                    AuthApiService.getAdmin(SessionData.getAdminID())
                    .then((response) => {
                        context.commit(SET_AUTH, response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        resolve()
                    })
            } else {
                context.commit(PURGE_AUTH)
                resolve()
            }
        })
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors.push(error)
    },
    [UNSET_ERROR](state){
      state.errors = [];
    },
    [SET_AUTH](state, response) {
        state.isAuthenticated = true;
        state.errors = [];
        SessionData.saveAdminID(response.data.user.id);
        JwtService.saveToken(response.headers['x-syncio-app-token']);
        state.admin = response.data.user;
        ApiService.setHeader();
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.errors = [];
        JwtService.destroyToken();
        SessionData.destroyAdminID();
    }
};

const namespaced = true

export default {
    namespaced,
    state,
    actions,
    mutations,
    getters
};
