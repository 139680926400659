import Vue from 'vue';
import moment from 'moment';

Vue.filter('humanReadableDate', (date) => {
  return moment(date).format('MMMM Do YYYY');
});

Vue.filter('humanReadableDateTime', (date) => {
  return moment(date).format('MMMM Do YYYY [at] hh:mm A');
});
