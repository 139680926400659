import ApiService from "@/common/api.service";

export const updateNote = {
  async updateNote({ commit }, { noteId, notes }) {
    try {
      await ApiService.post(`stores/${noteId}/notes-update`, { notes });
      commit('SET_RESPONSE_MESSAGE', { message: 'Note updated successfully.', type: 'primary' });
    } catch (error) {
      commit('SET_RESPONSE_MESSAGE', { message: 'Not authorised to update.', type: 'error' });
    }
  }
}
