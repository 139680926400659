const ADMIN_ID = "admin_id";

export const getAdminID = () => {
    return sessionStorage.getItem(ADMIN_ID);
};

export const saveAdminID = adminid => {
    sessionStorage.setItem(ADMIN_ID, adminid);
};

export const destroyAdminID = () => {
    sessionStorage.removeItem(ADMIN_ID);
};

export default { getAdminID, saveAdminID, destroyAdminID };