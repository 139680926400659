import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader


Vue.use(Vuetify);

const vuetify = new Vuetify({
    icons: {
        iconfont: 'md', // default - only for display purposes
    },
    theme: {
        themes: {
            light: {
                primary: '#0E3B4D',
                secondary: '#FCB058',
                anchor: '#FA757B',
            },
        },
    },
})

export default vuetify
