import { updateField } from 'vuex-map-fields';
import { getField } from 'vuex-map-fields';
import { ShopApiService } from "@/common/api.service";
import {
  FETCH_SHOPS,
  FETCH_SHOPS_LIST,
  FETCH_CURRENT_SHOP,
  FETCH_CURRENT_SHOP_CONNECTIONS,
  // UPDATE_SHOP,
  FETCH_AVAILABLE_WEBHOOK_DEFINITIONS,
  UPDATE_WEBHOOK_SETTING,
  FETCH_STORE_PRODUCTS,
  RESYNC_MAPPING_BY_CONNECTION,
  DELETE_STORE, UPDATE_PERMISSION,
  FETCH_DEBUG_WEBHOOK_DEFINITIONS
} from "@/store/actions.type";
import {
  SET_ERROR,
  UNSET_ERROR,
  SET_SHOPS,
  SET_SHOPS_LIST,
  UNSET_SHOPS_LIST,
  SET_CURRENT_SHOP,
  SET_CURRENT_PAGE,
  SET_CURRENT_SHOP_CONNECTIONS,
  SET_LOADING,
  SET_STORES_PAGINATION
} from "@/store/mutations.type";

import { UNSET_LOADING } from "../mutations.type";

const initialState = {
  errors: null,
  shops: {},
  currentShop: null,
  currentShopConnections: {},
  isLoading: false,
  availableWebhookDefinitions: null,
  availableDebugWebhookDefinitions: null,
  rawWebhooksData: null,
  storeDeleted:false,
  pagination: {},
  jailedStoresList: null,
  processingGroups: [],
};

export const state = { ...initialState };

const getters = {
  shops(state) {
    return state.shops.stores;
  },
  loadingTable(state) {
    return state.loadingTable;
  },
  currentShop(state) {
    return state.currentShop;
  },
  currentShopConnections(state) {
    return state.currentShopConnections;
  },
  isLoading(state) {
    return state.isLoading;
  },
  getField
};

const actions = {
  [FETCH_SHOPS](context, { pageNumber, searchStr }) {
    context.commit(SET_LOADING);
    context.commit(SET_CURRENT_PAGE, pageNumber);
    return new Promise((resolve) => {
      ShopApiService.getShops(pageNumber, searchStr)
        .then(({ data }) => {
          context.commit(SET_SHOPS, data);
          context.commit(UNSET_LOADING);
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          context.commit(SET_ERROR, "Failed to load stores!");
        });
    });
  },
  [FETCH_SHOPS_LIST](context, { pageNumber, searchStr }) {
    context.commit(SET_LOADING);
    context.commit(UNSET_SHOPS_LIST);
    return new Promise((resolve) => {
      ShopApiService.getShops(pageNumber, searchStr)
        .then(({ data }) => {
          context.commit(SET_SHOPS_LIST, data.stores);
          context.commit(UNSET_LOADING);
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          context.commit(SET_ERROR, "Failed to load stores!");
        });
    });
  },
  [FETCH_CURRENT_SHOP](context, shopId) {
    return new Promise((resolve) => {
      context.commit(SET_CURRENT_SHOP, {});
      ShopApiService.getShop(shopId)
        .then((response) => {
          if (response.data.success) {
            context.commit(SET_CURRENT_SHOP, response.data.store);
            resolve();
          } else {
            context.commit(SET_ERROR, response.data.error);
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit(SET_ERROR, "Failed to load store - " + shopId + "!");
        });
    });
  },
  [FETCH_CURRENT_SHOP_CONNECTIONS](context, { shopId, limiter, search_str, page }) {
    return new Promise((resolve) => {
      context.commit(SET_CURRENT_SHOP_CONNECTIONS, {});
      ShopApiService.getShopConnections(shopId, limiter, search_str, page)
        .then((response) => {
          if (response.data.success) {
            context.commit(SET_CURRENT_SHOP_CONNECTIONS, response.data.store_connections);
            const { current_page, last_page, next_page_url, prev_page_url, total } = response.data;
            const pagination = {
              current_page,
              last_page,
              next_page_url,
              prev_page_url,
              total
            }
            context.commit(SET_STORES_PAGINATION, pagination);
            resolve();
          } else {
            context.commit(SET_ERROR, response.data.error);
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit(SET_ERROR, "Failed to load store - " + shopId + "!");
        });
    });
  },
  // [UPDATE_SHOP](context, { payload }) {
  //     const { email, access_token, api_version } = payload;
  //     const stores = {
  //         email,
  //         access_token,
  //         api_version
  //     };
  //     return ShopApiService.updateShop(id, stores)
  //             .then((response) => {
  //                 context.commit(RESET_TOKEN, response);
  //                 return response.data;
  //             });
  // },
  [FETCH_AVAILABLE_WEBHOOK_DEFINITIONS]({state}, {shopId}) {
      return new Promise((resolve) => {
        return ShopApiService.getShopWebhooksByShopId(shopId)
          .then(({ data }) => {
            if (data.success) {
              state.availableWebhookDefinitions = data.webhooks;
              state.rawWebhooksData = data.raw_data;
            }
          })
          .finally(() => {
            resolve();
          });
      });
  },
  [FETCH_DEBUG_WEBHOOK_DEFINITIONS]({state}, {shopId}) {
    return new Promise((resolve) => {
      return ShopApiService.getShopDebugWebhooksByShopId(shopId)
          .then(({ data }) => {
            if (data.success) {
              state.availableDebugWebhookDefinitions = data.webhooks;
            }
          })
          .finally(() => {
            resolve();
          });
    });
  },
  [UPDATE_WEBHOOK_SETTING](context, payload) {
    return new Promise((resolve) => {
      // console.log(payload);
      // webhookSetting ='';
      if (payload.webhookSetting.enabled) {
        // adding webhook
        // console.log();
        ShopApiService.addingShopWebhook(payload.shopId, {"webhookSetting":payload.webhookSetting,"debug":payload.debug})
          .then(({data}) => {
            resolve(data);
          })
          .finally(() => {
            resolve();
          })
      } else {
        // removing webhook

        ShopApiService.removingShopWebhook(payload.shopId, {"webhookSetting":payload.webhookSetting,"debug":payload.debug})
          .then(({data}) => {
            resolve(data);
          })
          .finally(() => {
            resolve();
          })
      }
    });
  },
  [FETCH_STORE_PRODUCTS]() {
    return new Promise((resolve) => {
      ShopApiService.fetchProductsByStoreId(state.currentShop.id).then(({data}) => {
        resolve(data);
      })
      .finally(() => {
        resolve();
      });
    });
  },
  [RESYNC_MAPPING_BY_CONNECTION](context, connectionId) {
    return new Promise((resolve) => {
      ShopApiService.resyncProductsByConnectionId(connectionId)
          .then(({data}) => {
            resolve(data);
          });
    });
  },
  [DELETE_STORE](context, shopId) {
    return new Promise((resolve) => {
      ShopApiService.deleteStore(shopId)
          .then(({data}) => {
            state.storeDeleted=true
            resolve(data);
          });
    });
  },
  [UPDATE_PERMISSION](context, storeDomain) {
    return new Promise((resolve) => {
      ShopApiService.updatePermission(storeDomain)
          .then(({data}) => {
            resolve(data);
          });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [UNSET_ERROR](state) {
    state.errors = {};
  },
  [SET_SHOPS](state, data) {
    state.shops = data;
  },
  [SET_SHOPS_LIST](state, stores) {
    state.shops.stores = stores;
  },
  [SET_CURRENT_SHOP](state, shop) {
    state.currentShop = shop;
  },
  [SET_CURRENT_PAGE](state, currentPage) {
    state.shops.current_page = currentPage;
  },
  [SET_CURRENT_SHOP_CONNECTIONS](state, connections) {
    state.currentShopConnections = connections;
  },
  [SET_LOADING](state) {
    state.isLoading = true;
  },
  [UNSET_LOADING](state) {
    state.isLoading = false;
  },
  [UNSET_SHOPS_LIST](state) {
    state.shops.stores = [];
  },
  [SET_STORES_PAGINATION](state, payload) {
    state.pagination = payload;
  }
};

mutations['updateField'] = updateField

const namespaced = true;

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
