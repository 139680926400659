import deepmerge from "deepmerge";
import { updateField } from 'vuex-map-fields';

import { createCustomWebhook } from "./actions/createCustomWebhook";

/* Actions */
const actions = deepmerge.all([
  createCustomWebhook,
  {}
]);

const state = {
};

//mutations['updateField'] = updateField

export default {
  actions,
  state,
  namespaced: true
};
