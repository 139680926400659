import ApiService from "@/common/api.service";

export const createNote = {
  async createNote({ commit }, payload) {
    try {
      await ApiService.post('stores/notes-create', {
      ...payload
      });
      commit('SET_RESPONSE_MESSAGE', { message: 'Note created successfully.', type: 'primary' });
    } catch(error) {}
  }
};
