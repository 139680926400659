import deepmerge from "deepmerge";
import { updateField } from 'vuex-map-fields';

import { createTask } from "./actions/createTask";
import { deleteTask } from "./actions/deleteTask";
import { fetchTasks } from "./actions/fetchTasks";
import { updateTask } from "./actions/updateTask";

import { setCompletedTasks } from "./mutations/setCompletedTasks";
import { setInProgressTasks } from "./mutations/setInProgressTasks";
import { setResponseMessage } from "./mutations/setResponseMessage";

/* Actions */
const actions = deepmerge.all([
  createTask,
  deleteTask,
  fetchTasks,
  updateTask
]);

/* Mutations */
const mutations = deepmerge.all([
  setCompletedTasks,
  setInProgressTasks,
  setResponseMessage
]);

const state = {
  isLoading: true,
  responseMessage: '',
  tasks: [],
  tasksCompleted: []
};

import { getters } from "./getters";

mutations['updateField'] = updateField

export default {
  actions,
  getters,
  mutations,
  state,
  namespaced: true
};
