export const CHECK_AUTH = "checkAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const FETCH_USERS = "fetchUsers";
export const FETCH_USERS_LIST = "fetchUsersList";
export const DELETE_USER = "deleteUser";
export const UPDATE_STAFF = "updateStaff";
export const FETCH_STAFFS = "fetchStaffs";
export const FETCH_STAFFS_LIST = "fetchStaffsList";
export const FETCH_CURRENT_STAFF = "fetchCurrentStaff";
export const DELETE_STAFF = "deleteStaff";

export const FETCH_SHOPS = "fetchShops";
export const FETCH_SHOPS_LIST = "fetchShopsList";
export const FETCH_CURRENT_SHOP = "fetchCurrentShop";
export const FETCH_CURRENT_SHOP_CONNECTIONS = "fetchCurrentShopConnections";
export const UPDATE_SHOP = "updateShop";
export const FETCH_AVAILABLE_WEBHOOK_DEFINITIONS = "fetchAvailableWebhookDefintions";
export const FETCH_DEBUG_WEBHOOK_DEFINITIONS = "fetchDebugWebhookDefinitions";
export const DELETE_STORE = "deleteStore";
export const UPDATE_PERMISSION = "updatePermission";
export const UPDATE_WEBHOOK_SETTING = "updateWebhookSetting";
export const FETCH_AVAILABLE_MODULES = "fetchAvailableModules";
export const UPDATE_MODULE_BY_USER_ID = "updateModuleByUserId";
export const FETCH_STORE_PRODUCTS = "fetchStoreProducts";
export const RESYNC_MAPPING_BY_CONNECTION = "resyncMappingByConnection";

export const LOAD_CURRENT_CONFIGURATIONS = "loadCurrentConfigurations";
export const UPDATE_CURRENT_CONFIGURATIONS = "updateCurrentConfigurations";
export const FORMAT_AND_SET_CURRENT_CONFIGS = "formatAndSetCurrentConfigs";
export const RESET_CURRENT_CONFIGS = "resetCurrentConfigs";

export const FETCH_AVAILABLE_PLANS = "fetchAvailablePlans";
export const FETCH_USER_CURRENT_PLAN = "fetchUserCurrentPlan";
export const CREATE_CUSTOM_PLAN = "createCustomPlan";

// REPORTING
export const FETCH_STORES_COUNTS = "fetchStoresCounts";
export const FETCH_DASHBOARD_DATA = "fetchDashboardData";
