import ApiService from "@/common/api.service";

export const deleteNote = {
  async deleteNote({ commit }, payload) {
    try {
      await ApiService.deleteWithBody('stores/notes-delete', {
        data: { ...payload }
      });
      commit('SET_RESPONSE_MESSAGE', { message: 'Note deleted successfully.', type: 'primary' });
    } catch(error) {
      commit('SET_RESPONSE_MESSAGE', { message: 'Not authorised to delete.', type: 'error' });
    }
  }
};
