import {
    SET_CURRENT_CONFIGS,
} from "@/store/mutations.type";
import {
    RESET_CURRENT_CONFIGS, FORMAT_AND_SET_CURRENT_CONFIGS,
    LOAD_CURRENT_CONFIGURATIONS, UPDATE_CURRENT_CONFIGURATIONS
} from "@/store/actions.type";

import { ConfigsApiService } from "@/common/api.service";

const mutations = {
    [SET_CURRENT_CONFIGS](state, configs) {
        state.currentConfigs = configs;
    }
};

const actions = {
    [RESET_CURRENT_CONFIGS]({ commit }) {
        commit(SET_CURRENT_CONFIGS, null);
    },
    [LOAD_CURRENT_CONFIGURATIONS]({ dispatch }, { shopId }) {
        return new Promise((resolve) => {
            ConfigsApiService.loadConfigurations(shopId)
                .then(({ data }) => { 
                    if (data.success) {
                        // convert the data format
                        dispatch(FORMAT_AND_SET_CURRENT_CONFIGS, {
                            configsData: data.configurations
                        });
                    } else {
                        //TODO error handle
                    }
                    resolve(data);
                }).catch(({ data }) => {
                    resolve(data);
                });
        });
        
    },
    [UPDATE_CURRENT_CONFIGURATIONS]({ dispatch }, { shopId, newConfigSettings }) {
        let paramData = [];
        newConfigSettings.forEach((group) => {
            group.configs.forEach((config) => {
                paramData.push({
                    key: config.key,
                    is_active: config.value,
                });
            });
        });
        return new Promise((resolve) => {
            ConfigsApiService.saveConfigurations(shopId, paramData)
                .then(({ data }) => {
                    if (data.success) {
                        // convert the data format
                        dispatch(FORMAT_AND_SET_CURRENT_CONFIGS, {
                            configsData: data.configurations
                        });
                    } else {
                        //TODO error handle
                    }
                }).finally(() => {
                    resolve();
                });
        });
    },
    [FORMAT_AND_SET_CURRENT_CONFIGS](context, { configsData }) {
        let currentConfigsSet = {
            product: [],
            variant: [],
            general: [],
        };
        configsData.forEach((config) => {
            if (config.is_active === "1" || config.is_active === 1 || config.is_active === true) {
                config.value = true;
            } else {
                config.value = false;
            }
            if (config.group === "product") {
                currentConfigsSet.product.push(config);
            } else if (config.group === "variant") {
                currentConfigsSet.variant.push(config);
            } else if (config.group === "general") {
                currentConfigsSet.general.push(config);
            }
        });
        context.commit(SET_CURRENT_CONFIGS, currentConfigsSet);
    },
};

export default {
    namespaced: true,
    state: {
        currentConfigs: null,
    },
    mutations,
    actions,
}