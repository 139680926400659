import ApiService from "@/common/api.service";

export const fetchTasks = {
  async fetchTasks({ commit }, { storeId, userId }) {
    const response = await ApiService.get(`/stores/${storeId}/tasks/${userId}`);
    let inProgressTaskData = response.data?.tasks.in_progress ? response.data?.tasks.in_progress : [];
    let completedTaskData = response.data?.tasks.complete ? response.data?.tasks.complete : [];
    commit('SET_IN_PROGRESS_TASKS', inProgressTaskData);
    commit('SET_COMPLETED_TASKS', completedTaskData);
  }
};
