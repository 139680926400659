import { ReportingApiService } from "@/common/api.service";
import {
    FETCH_STORES_COUNTS,
    FETCH_DASHBOARD_DATA
} from "@/store/actions.type";

const state = {
    sourceStoreCount: null,
    destinationStoreCount: null,
    productMappersCount: null,
    platformStoresCount: null,
    recentInstalledStores: null,
    mlStoresCount: null,
};

const actions = {
    [FETCH_STORES_COUNTS]({state}) {
        return new Promise((resolve) => {
            ReportingApiService.fetchStoresCount().then(({success, data}) => {
                if (success) {
                    state.sourceStoreCount = data.source_store_count;
                    state.destinationStoreCount = data.destination_store_count;
                } else {
                    alert("fetch data error.")
                }
            }).finally(() => {
                resolve();
            });
        });
    },
    [FETCH_DASHBOARD_DATA]({state}) {
        return new Promise((resolve) => {
            ReportingApiService.getDashBoardData().then(({data}) => {
                if (data.success) {
                    const { 
                        source_store_count, 
                        destination_store_count, 
                        product_mappers_count, 
                        platform_store_count, 
                        last_5_installed_stores, 
                        ml_stores_count 
                    } = data.data;
                    
                    state.sourceStoreCount = source_store_count;
                    state.destinationStoreCount = destination_store_count;
                    state.productMappersCount = product_mappers_count;
                    state.platformStoresCount = platform_store_count;
                    state.recentInstalledStores = last_5_installed_stores;
                    state.mlStoresCount = ml_stores_count;
                } else {
                    alert('fetch dashboard data error.')
                }
            }).finally(() => {
                resolve();
            });
        });
    }
};

const mutations = {
};

const getters = {

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};