import { PlanApiService } from "@/common/api.service";
import { FETCH_AVAILABLE_PLANS, FETCH_USER_CURRENT_PLAN, CREATE_CUSTOM_PLAN } from "@/store/actions.type";

const state = {
    currentPlan: null,
    availablePlans: null,
};

const actions = {
    [FETCH_AVAILABLE_PLANS]({ state }) {
        return new Promise((resolve) => {
            PlanApiService.getAvailablePlans()
                .then(({data}) => {
                    if (data.success) {
                        state.availablePlans = data.plans;
                    }
                })
                .finally(() => {
                    resolve();
                });
        });
    },
    [FETCH_USER_CURRENT_PLAN] ({ state }, userId) {
        return new Promise((resolve) => {
            PlanApiService.getUserPlan(userId)
                .then(({data}) => {
                    if (data.success) {
                        state.currentPlan = data.plan;
                    }
                    resolve(data);
                })
                .finally((data) => {
                    resolve(data);
                });
        });
    },
    [CREATE_CUSTOM_PLAN] ({state}, { userId, data}) {
        return new Promise((resolve) => {
            PlanApiService.createCustomPlan(userId, data)
                .then(({ data }) => {
                    if (data.success) {
                        state.currentPlan = data.new_plan;
                    }
                    resolve(data);
                });
        })
    },
};

const mutations = {

};

const getters = {

};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
  