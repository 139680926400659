import { StaffApiService } from "@/common/api.service";
import {
  DELETE_STAFF,
  FETCH_CURRENT_STAFF,
  FETCH_STAFFS,
  FETCH_STAFFS_LIST,
  REGISTER,
  UPDATE_STAFF,
} from "@/store/actions.type";
import {
  SET_CURRENT_STAFF,
  SET_ERROR,
  SET_STAFFS,
  SET_STAFFS_LIST,
  SET_LOADING,
  UNSET_ERROR,
  SET_CURRENT_PAGE,
  UNSET_LOADING,
  UNSET_STAFFS_LIST,
} from "@/store/mutations.type";
import SessionData from "@/common/sessionData.service";

const initialState = {
  isLoading: true,
  staff: {},
  staffs: {},
  errors: [],
};

export const state = {
  ...initialState,
};

const getters = {
  staffs(state) {
    return state.staffs.staffs;
  },
  currentStaff(state) {
    return state.staff;
  },
  isLoading(state) {
    return state.isLoading;
  },
};

const actions = {
  [REGISTER](context, { is_active, name, email, password, password_confirmation, role }) {
    return new Promise((resolve, reject) => {
      StaffApiService.registerStaff({ is_active, name, email, password, password_confirmation, role })
        .then((response) => {
          resolve(response);
        })
        .catch((response) => {
          context.commit(SET_ERROR, response.error);
          reject(response);
        });
    });
  },
  [DELETE_STAFF](context, { userId }) {
    return new Promise((resolve, reject) => {
      StaffApiService.deleteStaff(userId)
        .then(() => {
          context
            .dispatch(FETCH_STAFFS)
            .then((data) => context.commit(SET_STAFFS, data.staffs));
          resolve();
        })
        .catch((response) => {
          context.commit(SET_ERROR, response.error);
          reject(response);
        });
    });
  },
  [UPDATE_STAFF](context, { id, is_active, name, email, password, password_confirmation, role }) {
    return new Promise((resolve, reject) => {
      StaffApiService.updateStaff(id, { is_active, name, email, password, password_confirmation, role })
        .then((response) => {
          resolve(response);
        })
        .catch((response) => {
          context.commit(SET_ERROR, response.error);
          reject(response);
        });
    });
  },
  [FETCH_CURRENT_STAFF](context) {
    return new Promise((resolve) => {
      StaffApiService.getStaff(SessionData.getStaffID())
        .then(({ data }) => {
          context.commit(SET_CURRENT_STAFF, data.user);
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          context.commit(SET_ERROR, "Load Staff Account Failed");
        });
    });
  },
  [FETCH_STAFFS](context, { pageNumber, searchStr }) {
    context.commit(SET_LOADING);
    context.commit(SET_CURRENT_PAGE, pageNumber);
    return new Promise((resolve) => {
      StaffApiService.getStaffs(pageNumber, searchStr)
        .then(({ data }) => {
          context.commit(SET_STAFFS, data);
          context.commit(UNSET_LOADING);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.error);
        });
    });
  },
  [FETCH_STAFFS_LIST](context, { pageNumber, searchStr }) {
    context.commit(UNSET_STAFFS_LIST);
    context.commit(SET_LOADING);
    return new Promise((resolve) => {
      StaffApiService.getStaffs(pageNumber, searchStr)
        .then(({ data }) => {
          context.commit(SET_STAFFS_LIST, data.staffs);
          context.commit(UNSET_LOADING);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.error);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors.push(error);
  },
  [UNSET_ERROR](state) {
    state.errors = [];
  },
  [SET_STAFFS](state, data) {
    state.staffs = data;
  },
  [UNSET_STAFFS_LIST](state) {
    state.staffs.staffs = [];
  },
  [SET_LOADING](state) {
    state.isLoading = true;
  },
  [UNSET_LOADING](state) {
    state.isLoading = false;
  },
  [SET_STAFFS_LIST](state, staffs) {
    state.staffs.staffs = staffs;
  },
  [SET_CURRENT_PAGE](state, currentPage) {
    state.staffs.current_page = currentPage;
  },
  [SET_CURRENT_STAFF](state, user) {
    state.user = user;
  },
};

const namespaced = true;

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
