import { UserApiService } from "@/common/api.service";
import {
  DELETE_USER,
  FETCH_USERS,
  FETCH_USERS_LIST,
  REGISTER,
  UPDATE_USER,
  FETCH_AVAILABLE_MODULES,
  UPDATE_MODULE_BY_USER_ID,
} from "@/store/actions.type";
import {
  SET_LOADING,
  UNSET_LOADING,
  SET_ERROR,
  SET_USERS,
  SET_USERS_LIST,
  UNSET_USERS_LIST,
  UNSET_ERROR,
  SET_CURRENT_PAGE,
  SET_USER_MODULES
} from "@/store/mutations.type";
import { ModuleApiService } from "../../common/api.service";

const initialState = {
  isLoading: true,
  user: {},
  users: {},
  errors: [],
  availableModules: null,
};

export const state = {
  ...initialState,
};

const getters = {
  users(state) {
    return state.users.users;
  },
  user(state) {
    return state.user;
  },
  isLoading(state) {
    return state.isLoading;
  },
};

const actions = {
  [REGISTER](context, { name, email, password, role }) {
    return new Promise((resolve, reject) => {
      UserApiService.registerUser({ name, email, password, role })
        .then(() => {
          context
            .dispatch(FETCH_USERS)
            .then((data) => context.commit(SET_USERS, data.users));
          resolve();
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.error);
          reject(response);
        });
    });
  },
  [DELETE_USER](context, { userId }) {
    return new Promise((resolve, reject) => {
      UserApiService.deleteUser(userId)
        .then(() => {
          context
            .dispatch(FETCH_USERS)
            .then((data) => context.commit(SET_USERS, data.users));
          resolve();
        })
        .catch((response) => {
          context.commit(SET_ERROR, response.error);
          reject(response);
        });
    });
  },
  [UPDATE_USER](context, { id, name, email, password, role }) {
    return new Promise((resolve, reject) => {
      UserApiService.updateUser(id, { name, email, password, role })
        .then(() => {
          context
            .dispatch(FETCH_USERS)
            .then((data) => context.commit(SET_USERS, data.users));
          resolve();
        })
        .catch((response) => {
          context.commit(SET_ERROR, response.error);
          reject(response);
        });
    });
  },
  [FETCH_USERS](context, { pageNumber, searchStr }) {
    context.commit(SET_LOADING);
    context.commit(SET_CURRENT_PAGE, pageNumber);
    return new Promise((resolve) => {
      UserApiService.getUsers(pageNumber, searchStr)
        .then(({ data }) => {
          context.commit(SET_USERS, data);
          context.commit(UNSET_LOADING);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.error);
        });
    });
  },
  [FETCH_USERS_LIST](context, { pageNumber, searchStr }) {
    context.commit(SET_LOADING);
    context.commit(UNSET_USERS_LIST);
    return new Promise((resolve) => {
      UserApiService.getUsers(pageNumber, searchStr)
        .then(({ data }) => {
          context.commit(SET_USERS_LIST, data.users);
          context.commit(UNSET_LOADING);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.error);
        });
    });
  },
  [FETCH_AVAILABLE_MODULES]({ commit }, userId) {
    return new Promise((resolve) => {
      ModuleApiService.loadModuleByUserId(userId)
      .then(({ data }) => {
        if (data.success) {
          commit(SET_USER_MODULES, data.modules);
        }
      })
      .finally(() => {
        resolve();
      });
    });
  },
  [UPDATE_MODULE_BY_USER_ID] ({ dispatch }, { userId, module }) {
    return new Promise((resolve) => {
      ModuleApiService.saveModuleByUserId(userId, module)
      .then(({ data }) => {
        if (data.success) {
          dispatch(FETCH_AVAILABLE_MODULES, userId)
        }
      })
      .finally(() => {
        resolve();
      });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors.push(error);
  },
  [UNSET_ERROR](state) {
    state.errors = [];
  },
  [SET_USERS](state, users) {
    state.users = users;
  },
  [UNSET_USERS_LIST](state) {
    state.users.users = [];
  },
  [SET_LOADING](state) {
    state.isLoading = true;
  },
  [UNSET_LOADING](state) {
    state.isLoading = false;
  },
  [SET_USERS_LIST](state, users) {
    state.users.users = users;
  },
  [SET_CURRENT_PAGE](state, currentPage) {
    state.users.current_page = currentPage;
  },
  [SET_USER_MODULES](state, payload) {
    state.availableModules = payload;
  }
};

const namespaced = true;

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
