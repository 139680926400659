import ApiService from "@/common/api.service";

export const updateTask = {
  async updateTask({ commit }, { taskId, task }) {
    try {
      await ApiService.post(`/stores/${taskId}/tasks-update`, {
        ...task
      });
      commit('SET_RESPONSE_MESSAGE', { message: 'Task updated successfully.', type: 'primary' });
    } catch (error) {
      commit('SET_RESPONSE_MESSAGE', { message: 'Something went wrong.', type: 'error' });
    }
  }
};
